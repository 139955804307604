import axios from "@/plugins/axios";
import { isSystemError } from "@/plugins/Error";
import { PageResult, SearchService } from ".";
const Paths = Object.freeze({
  List: "api/ApplicationUser",
  Create: "api/ApplicationUser",
  Get: (id: string, isForce: boolean | undefined = undefined) =>
    `api/ApplicationUser/${id}?isAll=true${
      isForce === true ? "&isForce=true" : ""
    }`,
  Save: (id: string) => `api/ApplicationUser/${id}`,
  Delete: (id: string) => `api/ApplicationUser/${id}`,
  ChangePassword: (id: string) => `api/ApplicationUser/${id}/password`,
  CloudUpload: (id: string) => `api/ApplicationUser/${id}/synchronize`,
});
export type SearchParams = {
  staffId: string;
  staffName: string;
  orderBy: string;
  isAll: string;
  isUploaded: string;
};
export type UserModel = {
  id: string;
  staffId: string;
  staffName: string;
  updateAt: string;
  uploadAt: string | null;
  roles: string[];
};
export type SearchResult = {
  list: UserModel[];
  page: PageResult;
};
export type UpdateUserModel = {
  staffId: string;
  needStaffName: boolean;
  staffName: string;
  needPassword: boolean;
  password: string;
};
/**
 * 権限
 */
export const Roles = Object.freeze({
  /** ユーザ権限 */
  User: "User",
  /** 管理者権限 */
  Admin: "Admin",
  /** 自動処理権限 */
  Automate: "Automate",
  /** 同期権限 */
  Synchronize: "Synchronize",
});
export type RegisterUserModel = {
  staffId: string;
  staffName: string;
  password: string;
};
export type ChangePasswordModel = {
  staffId: string;
  password: string;
};
export class UserService extends SearchService<SearchParams, UserModel> {
  constructor() {
    super({ omit: ["detail"] });
  }
  protected get getPath(): string {
    return Paths.List;
  }
  async cloudUpload(id: string): Promise<boolean> {
    try {
      const url = Paths.CloudUpload(id);
      await axios.post(url);
      return true;
    } catch (e) {
      console.error(e);
      if (isSystemError(e)) throw e;
      return false;
    }
  }
  async get(
    id: string,
    isForce?: boolean,
    options?: { signal?: AbortSignal }
  ): Promise<UserModel> {
    try {
      const url = Paths.Get(id, isForce);
      const response = await axios.get<UserModel>(url, options);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async create(model: RegisterUserModel): Promise<UserModel> {
    try {
      const url = Paths.Create;
      const response = await axios.post<UserModel>(url, model);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async save(model: UpdateUserModel): Promise<void> {
    try {
      const url = Paths.Save(model.staffId);
      await axios.put(url, model);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async delete(staffId: string): Promise<void> {
    try {
      const url = Paths.Delete(staffId);
      await axios.delete(url);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
export const User = new UserService();

/**
 * 削除可否判定
 * @param row
 * @returns
 */
export function isDeletable(row: UserModel | undefined): boolean {
  if (!row) return false;
  return !!row.roles.find((v) => v === Roles.User);
}
